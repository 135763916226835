<template>
  <div class="row justify-content-center">
    <div class="col alert alert-danger text-center" role="alert" v-html="message"></div>
  </div>
</template>
<script>
export default {
  props: {
    forUser: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    message() {
      let link = "<a href=\"https://support.twilio.com/hc/en-us/articles/223134027-Twilio-support-for-opt-out-keywords-SMS-STOP-filtering-\" target=\"_blank\">this article</a>"
      if (this.forUser) {
        return "You have been unsubscribed from our text number or do not agree to receive texts from us and can't receive any updates or notifications via text. Please see " + link + " for more details and for instructions on how you can allow us to send texts again."
      }
        return "The user has either been unsubscribed from our text number or does not agree to receive texts and can't receive any updates or notifications via text. Please see " + link + " for more details and for instructions on how the texts can be enabled again."
    }
  }
}
</script>
