<template>
  <b-form-checkbox :disabled="readonly" v-model="innerValue" :id="$attrs.id" :name="name">
    <p class="text-tiny mb-0 pt-1"><slot /></p>
  </b-form-checkbox>
</template>

<script>

export default {
  inheritAttrs: false,
  props: {
    // must be included in props
    value: {
      type: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    name: {
      type: String
    },
  },
  data: () => ({
    innerValue: null,
    forcedState: null
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
  }
}
</script>
